import { Component } from '@angular/core';

@Component({
    selector: 'app-offline-modal',
    templateUrl: './offline-modal.component.html',
    styleUrl: './offline-modal.component.css',
    standalone: false
})
export class OfflineModalComponent {

}
